.root {
  display: flex;
  flex-direction: column;
}

.rootWithoutMap {
  & .listingCards {
    @media (--viewportMedium) {
      grid-template-columns: repeat(4, 1fr);
    }
  }
}

.listingCards {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 24px;
  margin-top: 24px;

  @media (--viewportMedium) {
    margin-top: 0;
    grid-template-columns: repeat(2, 1fr);
  }
}

.listingCard {
}

.pagination {
  /* margin-top: auto; pushes pagination to the end of the page. */
  margin-top: auto;
  background-color: var(--matterColorLight);
  padding: 0 24px;

  @media (--viewportLarge) {
    padding: 0 36px;
  }
}
